var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card-shadow border-radius-xl",class:_vm.$route.name === 'VrDefault' ? 'bg-gradient-default' : ''},[_c('div',{staticClass:"px-6 pt-4 pb-0 d-flex"},[_c('v-avatar',{staticClass:"shadow",attrs:{"color":"bg-gradient-success border-radius-xl mt-n8","height":"64","width":"64"}},[_c('v-icon',{staticClass:"material-icons-round text-white",attrs:{"size":"24"}},[_vm._v("language")])],1),_c('p',{staticClass:"font-weight-bold text-h6 mb-0 ms-3",class:_vm.$route.name === 'VrDefault' ? 'text-white' : 'text-typo'},[_vm._v(" Stock by Country ")])],1),_c('v-row',[_c('v-col',{attrs:{"lg":"6","md":"5"}},[_c('v-card-text',{staticClass:"py-3"},[_c('v-list',{staticClass:"py-0 bg-transparent"},_vm._l((_vm.countries),function(chat,index){return _c('v-list-item',{key:chat.country.country,staticClass:"px-4 py-1",class:{ 'border-bottom': index !== _vm.countries.length - 1 }},[_c('v-list-item-content',{staticClass:"d-inline-flex"},[_c('span',{staticClass:"text-xs font-weight-bold",class:_vm.$route.name === 'VrDefault' ? 'text-white' : 'text-body'},[_vm._v(" Country: ")]),_c('v-list-item-title',{staticClass:"text-sm mb-0",class:_vm.$route.name === 'VrDefault' ? 'text-white opacity-6' :'text-typo'},[_c('flag',{staticClass:"mr-2",attrs:{"iso":chat.country.iso}}),_c('span',{staticClass:"text-xs",class:_vm.$route.name === 'VrDefault' ? 'text-white opacity-6' :'text-typo'},[_vm._v(_vm._s(chat.country.country))])],1)],1),_c('v-list-item-content',{staticClass:"text-center"},[_c('span',{staticClass:"text-xs font-weight-bold",class:_vm.$route.name === 'VrDefault' ? 'text-white' : 'text-body'},[_vm._v(" Q.tà: ")]),_c('v-list-item-title',{staticClass:"text-sm mb-0",class:_vm.$route.name === 'VrDefault'
                    ? 'text-white opacity-6'
                    : 'text-typo'},[_vm._v(_vm._s(chat.total))])],1),_c('v-list-item-content',{staticClass:"text-center"},[_c('span',{staticClass:"text-xs font-weight-bold",class:_vm.$route.name === 'VrDefault' ? 'text-white' : 'text-body'},[_vm._v(" Rossi: ")]),_c('v-list-item-title',{staticClass:"text-sm mb-0",class:_vm.$route.name === 'VrDefault'
                    ? 'text-white opacity-6'
                    : 'text-typo'},[_vm._v(_vm._s(chat.types[0].rossi))])],1),_c('v-list-item-content',{staticClass:"text-center"},[_c('span',{staticClass:"text-xs font-weight-bold",class:_vm.$route.name === 'VrDefault' ? 'text-white' : 'text-body'},[_vm._v(" Bianchi: ")]),_c('v-list-item-title',{staticClass:"text-sm mb-0",class:_vm.$route.name === 'VrDefault'
                    ? 'text-white opacity-6'
                    : 'text-typo'},[_vm._v(_vm._s(chat.types[0].bianchi))])],1),_c('v-list-item-content',{staticClass:"text-center"},[_c('span',{staticClass:"text-xs font-weight-bold",class:_vm.$route.name === 'VrDefault' ? 'text-white' : 'text-body'},[_vm._v(" Bollicina: ")]),_c('v-list-item-title',{staticClass:"text-sm mb-0",class:_vm.$route.name === 'VrDefault'
                    ? 'text-white opacity-6'
                    : 'text-typo'},[_vm._v(_vm._s(chat.types[0].bubble))])],1),_c('v-list-item-content',{staticClass:"text-center"},[_c('span',{staticClass:"text-xs font-weight-bold",class:_vm.$route.name === 'VrDefault' ? 'text-white' : 'text-body'},[_vm._v(" Rosè: ")]),_c('v-list-item-title',{staticClass:"text-sm mb-0",class:_vm.$route.name === 'VrDefault'
                    ? 'text-white opacity-6'
                    : 'text-typo'},[_vm._v(_vm._s(chat.types[0].rosè))])],1)],1)}),1)],1)],1),_c('v-col',{attrs:{"lg":"6","md":"5"}},[_c('div',{staticClass:"mt-0 mt-lg-n4 h-100 w-100 overflow-hidden",attrs:{"id":"map"}})])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }