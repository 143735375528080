<template>
  <v-card
      class="card-shadow border-radius-xl px-4 py-5"
      :class="$route.name === 'VrDefault' ? 'bg-gradient-default' : ''"
  >
    <div
        class="
        bg-gradient-primary
        shadow-primary
        border-radius-lg
        py-3
        pe-1
        mb-6
        mt-n12
      "
    >
      <div class="chart-area">
        <canvas
            :height="$route.name === 'VrDefault' ? '370' : '170'"
            :id="websiteViewsID"
        >
        </canvas>
      </div>
    </div>
    <h6
        class="ms-2 mt-6 mb-0 text-h6 font-weight-bold"
        :class="$route.name === 'VrDefault' ? 'text-white' : 'text-typo'"
    >
      Fatturato Settimanale Store
    </h6>
    <p class="text-sm ms-2 text-body font-weight-light">
      Andamento vendita Vino dello store di
      <span style="color: #cb0c9f" v-if="this.$store.state.auth.status.loggedIn">{{ this.$store.state.auth.user.store_name }}</span>
    </p>
    <hr class="horizontal dark mb-4 mx-2"/>
    <div class="d-flex text-body mx-2">
      <i class="material-icons-round text-sm my-auto me-1">schedule</i>
      <p class="mb-0 text-sm font-weight-light text-body">
        Il fatturato dello store di settimana scorsa
      </p>
    </div>
  </v-card>
</template>
<script>
import Chart from "chart.js/auto";
import ReportService from "@/services/report.service";
import {data} from "browserslist";

export default {
  name: "card-website-views",
  computed: {
    data() {
      return data
    }
  },
  data: function () {
    return {
      websiteViewsID: "websiteViews",
      dataset: []
    };
  },
  async mounted() {
    await this.getFatturatoSettimanale()
  },
  methods: {
    getFatturatoSettimanale() {
      ReportService.getFatturatoLastWeek()
          .then(resp => {
            this.dataset = resp.data.data.map(item => item.incasso)
          })
          .catch()
          .finally(this.getChart)
    },
    getChart() {
      new Chart(document.getElementById(this.websiteViewsID).getContext("2d"), {
                type: "bar",
                data: {
                  labels: ["L", "M", "M", "G", "V", "S", "D"],
                  datasets: [
                    {
                      label: "Fatturato",
                      tension: 0.4,
                      borderWidth: 0,
                      borderRadius: 4,
                      borderSkipped: false,
                      backgroundColor: "rgba(255, 255, 255, .8)",
                      data: this.dataset,
                      maxBarThickness: 6,
                    },
                  ],
                },
                options: {
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                  },
                  interaction: {
                    intersect: false,
                    mode: "index",
                  },
                  scales: {
                    y: {
                      grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                        color: "rgba(255, 255, 255, .2)",
                      },
                      ticks: {
                        suggestedMin: 0,
                        suggestedMax: 500,
                        beginAtZero: true,
                        padding: 10,
                        font: {
                          size: 14,
                          weight: 300,
                          family: "Plus Jakarta Sans",
                          style: "normal",
                          lineHeight: 2,
                        },
                        color: "#fff",
                      },
                    },
                    x: {
                      grid: {
                        drawBorder: false,
                        display: true,
                        drawOnChartArea: true,
                        drawTicks: false,
                        borderDash: [5, 5],
                        color: "rgba(255, 255, 255, .2)",
                      },
                      ticks: {
                        display: true,
                        color: "#f8f9fa",
                        padding: 10,
                        font: {
                          size: 14,
                          weight: 300,
                          family: "Plus Jakarta Sans",
                          style: "normal",
                          lineHeight: 2,
                        },
                      },
                    },
                  },
                },
              })
    }

  }
};
</script>
