<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row class="mb-6 mt-15">
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10">
          <card-website-views></card-website-views>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10">
          <card-daily-sales></card-daily-sales>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0">
          <card-completed-tasks></card-completed-tasks>
        </v-col>
      </v-row>
      <v-row class="mb-12">
        <v-col md="12">
          <sales-country></sales-country>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SalesCountry from "./Widgets/SalesCountry.vue";
import CardWebsiteViews from "./Widgets/CardWebsiteViews.vue";
import CardDailySales from "./Widgets/CardDailySales.vue";
import CardCompletedTasks from "./Widgets/CardCompletedTasks.vue";

export default {
  name: "Dashboard",
  components: {
    SalesCountry,
    CardWebsiteViews,
    CardDailySales,
    CardCompletedTasks,
  },
  data() {
    return {
      imageCards: [
        {
          image: require("@/assets/img/product-1-min.jpeg"),
          title: "Cozy 5 Stars Apartment",
          text: "The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to 'Naviglio' where you can enjoy the main night life in Barcelona.",
          price: "899",
          location: "Barcelona, Spain",
        },
        {
          image: require("@/assets/img/product-2-min.jpeg"),
          title: "Office Studio",
          text: "The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to 'Naviglio' where you can enjoy the main night life in Barcelona.",
          price: "1.119",
          location: "London, UK",
        },
        {
          image: require("@/assets/img/product-3-min.jpeg"),
          title: "Beautiful Castle",
          text: "The place is close to Barceloneta Beach and bus stop just 2 min by walk and near to 'Naviglio' where you can enjoy the main night life in Barcelona.",
          price: "459",
          location: "Milan, Italy",
        },
      ],
    };
  },
};
</script>
